import { gql } from 'graphql-tag'

import { LinkFragment, ImageFragment, MultipleImagesFragment } from './fragments/parts.js'

export default gql`
  query Page($slug: String!) {
    page(slug: $slug) {
      data {
        id
        attributes {
          title
          slug
          meta_data {
            ... on ComponentStructureMetaData {
              title
              description
              author
              image {
                ... on UploadFileEntityResponse {
                  ...ImageFragment
                }
              }
            }
          }

          sections {
            ... on PageSectionsDynamicZone {
              __typename
              ... on ComponentSectionsHero {
                preheading
                heading
                sectionID
              }
              ... on ComponentSectionsHeroSmall {
                heading
                sectionID
              }
              ... on ComponentSectionsRichText {
                body
                sectionID
              }

              ... on ComponentSectionsCareers {
                preheading
                heading
                subheading
                body
                sectionID
              }

              ... on ComponentSectionsCareersExternal {
                preheading
                heading
                subheading
                body
                link:CTA {
                  ...LinkFragment
                }
                sectionID
              }

              ... on ComponentSectionsNews {
                preheading
                heading
                sectionID
              }

              ... on ComponentSectionsGallery {
                preheading
                heading
                body
                images {
                  ...MultipleImagesFragment
                }
                intro
                sectionID
              }

              ... on ComponentSectionsVideo {
                heading
                subheading
                poster {
                  ...ImageFragment
                }
                videoEmbed {
                  embedCode
                  source
                }
                videoLocal {
                  mp4 {
                    ...ImageFragment
                  }
                  webm {
                    ...ImageFragment
                  }
                }
                info {
                  title
                  text
                }
                sectionID
              }

              ... on ComponentSectionsNewsletter {
                heading
                contactList
                genres {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                sectionID
              }
              ... on ComponentSectionsCta {
                heading
                CTA {
                  ...LinkFragment
                }
                sectionID
              }
              ... on ComponentSectionsInvestors {
                preheading
                investors {
                  link {
                    ...LinkFragment
                  }
                  logo {
                    ...ImageFragment
                  }
                }
                sectionID
              }
            }
          }
        }
      }
    }
  }
  ${ImageFragment}
  ${LinkFragment}
  ${MultipleImagesFragment}
`
